/* All the CSS for the whole site basically */

* {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: white
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border: 2px solid #f2f2f2;
  border-radius: 12px;

}

.navbar .links {
  margin: auto;

  
}

.navbar a {
  padding: 20px;

}


.navbar a:hover {
  color: #dfdfdf;

}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

